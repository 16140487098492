<template>
  <div>
    <!-- 微信弹框 -->
    <el-dialog
      style="margin-top: 18vh; padding: 0"
      :visible.sync="wxdialogVisible"
      :show-close="false"
      :close-on-click-modal="false"
      width="330px"
      center
    >
      <div
        style="
          text-align: center;
          color: #111;
          font-weight: 600;
          font-size: 18px;
          font-family: 微软雅黑;
          margin-bottom: 40px;
        "
      >
        下载：17{{ randomNumber }}.pdf
      </div>
      <div
        style="border-top: 1px solid #e6e6e6; display: flex; text-align: center"
      >
        <div
          style="
            flex: 1;
            color: #000;
            font-weight: 500;
            font-size: 18px;
            font-family: 微软雅黑;
            padding: 15px 0;
            border-right: 1px solid #e6e6e6;
            cursor: pointer;
          "
          @click="cancel()"
        >
          取消
        </div>
        <div
          style="
            flex: 1;
            color: #627eab;
            cursor: pointer;
            font-size: 18px;
            padding: 15px 0;
            font-weight: 600;
            cursor: pointer;
          "
          @click="wxSubmit()"
        >
          确定
        </div>
      </div>
    </el-dialog>

    <!-- <img v-if="imgActive == true" style="width: 100%" :src="imgUrl" alt="" /> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgUrl: "",
      url: "",
      isShow: false,
      code: null,
      isWeixin: false,
      isAndroid: false,
      isIOS: false,
      wxdialogVisible: false,
      imgActive: false,
      randomNumber: "",
    };
  },
  created() {
    this.getUrlCode("code");
    let ua = navigator.userAgent.toLowerCase(); //判断是否是微信浏览器
    this.isWeixin = ua.indexOf("micromessenger") != -1; //判断是否
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    this.isAndroid = userAgent.toLowerCase().indexOf("android") > -1;
    this.isIOS =
      (/iPad|iPhone|iPod/.test(userAgent) && !this.isAndroid) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
    this.randomNumber = Math.random().toString().slice(2, 13);
  },
  mounted() {},
  methods: {
    getCode() {
      let data = {
        fwm: this.code,
      };
      // 转换对象为查询字符串
      const urlParams = new URLSearchParams(data);
      // const url = `https://admin.atqf.cn/prod-api/atqf/shbxjfzm/fwm?${data}`;
      const url = `http://47.121.185.22:8088/atqf/shshbxjfzm/fwm?${urlParams.toString()}`;
      const options = {
        method: "GET", // 请求方法
        headers: {
          "Content-Type": "application/json", // 设置请求头
        },
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((res) => {
          this.url = res.data.file;
          this.imgUrl = res.data.img;
          if (this.isWeixin == true && this.isIOS) {
            this.wxdialogVisible = true;
          } else {
            window.location.href = this.url;
          }
        })
        .catch((error) => console.error("Error:", error));
      return;
    },
    wxSubmit() {
      const urlArray = this.imgUrl.split(",").map((url) => url.trim());
      // 清除空字符串
      const filteredUrlArray = urlArray.filter((url) => url !== "");
      // 将过滤后的 URL 数组推送到数据属性中
      let urls = [];
      urls.push(...filteredUrlArray);

      this.$router.push({
        name: "shContent",
        query: { array: JSON.stringify(urls) },
      });
      this.wxdialogVisible = false;
      // this.imgActive = true;
    },
    cancel() {
      this.wxdialogVisible = false;
    },
    getUrlCode(variable) {
      var query = window.location.href.substring(1);
      var vars = query.split("?");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          this.code = pair[1];
        }
      }

      setTimeout(() => {
        this.getCode();
      }, 1000);
    },
  },
};
</script>
<style scoped>
::v-deep .el-dialog--center {
  border-radius: 10px !important;
}
::v-deep .el-dialog__body {
  padding-top: 10px !important;
  padding-bottom: 0 !important;
}
</style>