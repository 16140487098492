<template>
  <div style="background-color: #ededed; min-height: 100vh; padding: 30px 0">
    <el-card
      style="margin-top: 5px"
      v-for="(item, index) in array"
      :key="index"
    >
      <img style="width: 100%" :src="item" alt="" />
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      gzUrl: "",
      array: [],
    };
  },
  created() {
    const arrayString = this.$route.query.array;
    if (arrayString) {
      this.array = JSON.parse(arrayString);
      console.log(this.array, "this.array");
    }
    // this.gzUrl = this.$route.query.url;
  },
  methods: {},
};
</script>